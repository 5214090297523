const FLOATING_BUTTON_AREA_DEFAULT_POSITION = {
  desktop: 24,
  mobile: 50,
};

/**
 * (모바일) 채널톡 아이콘의 높이 사이즈
 */
const MOBILE_CHAT_BUTTON_HEIGHT = 44;

export { FLOATING_BUTTON_AREA_DEFAULT_POSITION, MOBILE_CHAT_BUTTON_HEIGHT };
