import { atom } from "jotai";

/**
 * 우하단에 위치하는 플로팅 버튼 영역(`FloatingButtonArea`)의 위치
 * - 초기화는 layout의 `useInitFloatingButtonAreaPosition` 훅을 통해 처리된다. (device별로 다르게 위치가 초기화되어야하므로 컴포넌트레벨에서 처리)
 *
 * - 유의사항: `특정 페이지에서만 위치를 변경하고 싶을때`는 직접 state를 변경하지말고 `useCustomizeFloatingButtonAreaPosition` 훅을 통해 사용할 것을 권장.
 *   - 해당 페이지에서 나왔을때 default값으로 되돌려야 하므로, 관련 오작동을 방지하기 위함
 */
const FLOATING_BUTTON_AREA_POSITION = atom<number>(0);

const CHAT_BUTTON_UNREAD_COUNT = atom<number | undefined>(undefined);

const OPENS_POP_UP_FOR_SHIPDA = atom<boolean>(true);

export default {
  FLOATING_BUTTON_AREA_POSITION,
  CHAT_BUTTON_UNREAD_COUNT,
  OPENS_POP_UP_FOR_SHIPDA,
};
