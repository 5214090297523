import { useEffect } from "react";
import { useSetAtom } from "jotai";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { COMMON_LAYOUT_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/layout";

import { FLOATING_BUTTON_AREA_DEFAULT_POSITION } from "../../constants/layout";
/**
 * 플로팅 버튼영역(`FLOATING_BUTTON_AREA`)의 위치를 초기화
 */
export default function useInitFloatingButtonAreaPosition() {
  const { isMobile } = useCheckIsMobile();

  const setFloatingButtonAreaPosition = useSetAtom(
    COMMON_LAYOUT_ATOMS.FLOATING_BUTTON_AREA_POSITION
  );

  // device에 따른 기본값을 셋팅
  useEffect(() => {
    setFloatingButtonAreaPosition(
      FLOATING_BUTTON_AREA_DEFAULT_POSITION[isMobile ? "mobile" : "desktop"]
    );
  }, [isMobile, setFloatingButtonAreaPosition]);
}
