import { loadExternalScriptAndRunDependentCallback } from "@sellernote/_shared/src/utils/common/etc";

import { USES_PRODUCTION_TRACKING } from "../../constants";

/**
 * naver 광고 로그분석 - 페이지 트래킹
 *
 * @remarks
 * 반드시 '공통 스크립트' 코드보다 먼저 실행되어야 함('공통 스크립트'에서만 로그 전송 method가 실행되기때문에, 이후에 실행되면 로그 전송이 안됨)
 *
 * @returns 비동기 함수로, 반환값은 없습니다.
 */
export async function trackPageByNaver() {
  if (!USES_PRODUCTION_TRACKING) {
    return;
  }

  loadExternalScriptAndRunDependentCallback(
    "https://wcs.naver.net/wcslog.js",
    () => {
      const windowObj = window as any;

      setNaverAccountId(windowObj);

      if (!windowObj._nasa) {
        windowObj._nasa = {};
      }

      if (windowObj.wcs) {
        // 구형 모바일 브라우저에서 이따금씩 로드가 잘 안되는 문제가 있어 추가
        windowObj.wcs.inflow();
      }

      sendNaverTrackingLog(windowObj);
    }
  );
}

/**
 * naver 광고 로그분석 - 전환 스크립트
 * 숫자를 스트링형태로 받음
 * 반드시 '공통 스크립트' 코드보다 먼저 실행되어야 함('공통 스크립트'에서만 로그 전송 method가 실행되기때문에, 이후에 실행되면 로그 전송이 안됨)
 *
 * @remarks
 * 이 함수는 반드시 '공통 스크립트' 코드보다 먼저 실행되어야 합니다. '공통 스크립트'에서만 로그 전송 메서드가 실행되기 때문에, 이후에 실행되면 로그 전송이 안 됩니다.
 *
 * @param tType - 전환유형을 나타내는 문자열
 * @param tValue - 전환가치를 나타내는 문자열
 *
 * @returns 비동기 함수로, 반환값은 없습니다.
 */
export async function trackTransitionByNaver(tType: string, tValue: string) {
  if (!USES_PRODUCTION_TRACKING) {
    return;
  }

  loadExternalScriptAndRunDependentCallback(
    "https://wcs.naver.net/wcslog.js",
    () => {
      const windowObj = window as any;

      setNaverAccountId(windowObj);

      if (!windowObj._nasa) {
        windowObj._nasa = {};
      }
      windowObj._nasa.cnv = windowObj.wcs.cnv(tType, tValue);

      sendNaverTrackingLog(windowObj);
    }
  );
}

/**
 * naver 광고 로그분석 - 로그 전송
 *
 * @param windowObj - window 객체
 *
 * @returns 반환값은 없습니다.
 */
function sendNaverTrackingLog(windowObj: any) {
  if (windowObj.wcs_do) {
    windowObj.wcs_do(windowObj._nasa);
  }

  // 전송후 초기화 해주어야 중복 전송이 안 된다.
  windowObj._nasa = {};
}

/**
 * naver 광고 로그분석 - 계정 ID 설정
 *
 * @param windowObj - window 객체
 *
 * @returns 반환값은 없습니다.
 */
function setNaverAccountId(windowObj: any) {
  if (!windowObj.wcs_add) {
    windowObj.wcs_add = {};
  }
  windowObj.wcs_add.wa = "s_5946d4011fc7";
}

/**
 * 회원가입 페이지에서 가입 버튼을 클릭하는 경우
 *
 * @remarks
 * 이 함수는 회원가입 페이지에서 가입 버튼을 클릭할 때 호출됩니다.
 *
 * @returns 비동기 함수로, 반환값은 없습니다.
 */
export function trackTransitionByNaverForRegister() {
  trackTransitionByNaver("2", "6000");
}

/**
 * 업무의뢰나 티켓발급 버튼을 클릭하는 경우
 *
 * @remarks
 * 이 함수는 업무의뢰나 티켓발급 버튼을 클릭할 때 호출됩니다.
 *
 * @returns 비동기 함수로, 반환값은 없습니다.
 */
export function trackTransitionByNaverForRequest() {
  trackTransitionByNaver("4", "10000");
}

/**
 * 마이페이지에서 수출자 정보입력 누른 이후 '완료' 버튼을 클릭하는 경우
 *
 * @remarks
 * 이 함수는 마이페이지에서 수출자 정보입력 후 '완료' 버튼을 클릭할 때 호출됩니다.
 *
 * @returns 비동기 함수로, 반환값은 없습니다.
 */
export function trackTransitionByNaverForPurchase() {
  trackTransitionByNaver("1", "50000");
}
